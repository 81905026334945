<script>
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseMenu from '/~/components/base/menu/base-menu.vue'
import { editMembers } from '/~rec/components/chat/chat-menu'

export default {
  name: 'rec-chat-menu',
  components: {
    BaseButton,
    BaseMenu,
  },
  props: {
    room: {
      type: Object,
      required: true,
    },
  },
  computed: {
    menu() {
      const { room } = this

      return [
        {
          icon: 'rec/edit',
          content: 'Edit Chat',
          hidden: !room.isMeOwner || !room.isChannel,
          disabled: false,
          click: () => {
            modal.show('rec-chat-settings', {
              props: { room },
              on: {
                save: () => {
                  modal.hide()
                },
                members: () => {
                  this.editMembers()
                },
              },
            })
          },
        },
        {
          icon: 'trash',
          content: 'Delete Chat',
          hidden: !room.isMeOwner,
          disabled: false,
          click: () => {
            modal.show('rec-chat-delete', {
              props: { room },
              on: {
                success: () => {
                  modal.hideAll()
                },
              },
            })
          },
        },
        {
          icon: 'rec/leave',
          content: 'Leave Chat',
          disabled: false,
          click: async () => {
            modal.show('rec-chat-leave', {
              props: { room },
              on: {
                success: () => {
                  modal.hideAll()
                },
              },
            })
          },
        },
      ]
    },
  },
  methods: {
    editMembers,
  },
}
</script>

<template>
  <base-menu class="z-40" position="right" :items="menu">
    <template #trigger>
      <base-button icon="plain/more" class="block" />
    </template>
  </base-menu>
</template>
